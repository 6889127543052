<template>
  <div class="bg-light">
    <DefaultHeader/>
    <b-container class="bg-light px-5">
      <b-row>
        <b-col>
          <h3 class="mt-3">Cancelamento de Cartão</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <p class="pl-2 mt-4">
            Para cancelar o seu cartão, basta preencher os dados abaixo. Caso queira solicitar a segunda via, 
            após o preenchimento dos dados clique em <span style="color: #002e8a">Cancelar e solicitar 2ª Via</span>, 
            se não quiser solicitar a 2ª Via clique em <span style="color: #002e8a">Somente cancelamento</span>.
          </p>
          <p class="pl-2 mt-4">
            O formulário deve ser preenchido com os dados do <span style="color: #002e8a">titular do cartão</span>.
          </p>
        </b-col>
      </b-row>

      <RequestForm @submitAction="registerRequest($event)" @submitTicketAction="registerTicketRequest($event)" formType="cancelTicket"/>

      <CommonModals @submit="sellForm()" @secondSubmit="resetForm()"/>

      <CancelFeedbackModals :protocol="protocol" type="cancelTicket"/>

      <PrivacyModal/>

    </b-container>
  </div>
</template>

<script>
import CommonModals from '../components/modals.vue'
import CancelFeedbackModals from '../components/cancelFeedbackModal.vue'
import RequestForm from '../components/requestForm.vue'
import PrivacyModal from '../components/privacyModal.vue'
import DefaultHeader from '../components/defaultHeader.vue'

export default {
	components: {
		CommonModals,
    CancelFeedbackModals,
    RequestForm,
    PrivacyModal,
    DefaultHeader
	},
  data() {
    return {
      protocol: {
        cancelling: '',
        secondTicket: ''
      },
    }
  },
  mounted() {
    this.$bvModal.show('privacy-modal')
  },
  methods: {
    async registerRequest(data, singleRequest = true) {
      await this.callApi('post', 'registerRequest', data).then(response => {
        if(response.status == 200) {
          this.protocol.cancelling = response.data
          this.$emit('resetForm')
          if(singleRequest) this.$bvModal.show('cancel-feedback-modal')
          return
        }
        this.$bvModal.show('error-modal')
      }).catch((err) => {
        console.log(err)
      });
    },
    async registerTicketRequest(data) {
      this.registerRequest(data, false)
      await this.callApi('post', 'registerNewRequest', this.newPayload(data)).then(response => {
        if(response.status == 201) {
          this.protocol.secondTicket = response.data.id
          this.$emit('resetForm')
          this.$bvModal.show('second-ticket-modal')
          return
        }
        this.$bvModal.show('error-modal')
      }).catch((err) => {
        console.log(err)
      });
    },
    newPayload(data) {
      return {
        cpf: data.clientData.cpf,
        rg: data.clientData.rg,
        name: data.clientData.name,
        email: data.contactData.email,
        residentialNumber: data.contactData.phone,
        cellphoneNumber: data.contactData.cellphone,
        whatsApp: data.contactData.whats,
        cardCategory: data.requestData.cardCategory
      }
    }
  }
}
</script>

<style>
.container {
  height: calc(100vh - 56px);
  /* overflow: hidden; */
}
</style>